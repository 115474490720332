<template>
  <footer v-if="this.FooterData">
    <!-- 尾 -->
    <div class="container">
        <div class="text-box" v-html="this.FooterData.Footer_Text">
        </div>
      </div>
      <div class="bottom">
        <p>Copyright © 2022 Tainan City Government.</p>
      </div>
  </footer>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "Footer",
  data() {
    return {
      FooterData: {},
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Footer",
        {
          params: {},
        }
      )
      .then((response) => {
        this.FooterData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div>
    <Head />

    <!-- 列表內頁 -->
    <div class="wrapper list form" v-if="PostData && PostData.Post">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ PostData.Post.Title }}
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <div class="title-box">
            <h1>
              <img class="h1-img" src="/img/h1-service.svg" alt="" />
              {{ PostData.Post.Title }}
            </h1>
            <div class="time">
              {{ PostData.Post.PublicStartTime | timeString("YYYY-MM-DD") }}
            </div>
          </div>
          <div class="text-box" v-if="PostData.Post.Type == 0">
            <div class="img-carousel" v-if="PostData.Post.Image || Slideshows">
              <div v-if="PostData.Post.Image">
                <img :src="PostData.SiteUrl + PostData.Post.Image" :alt="PostData.Post.ImageAlt" />
              </div>
              <div v-for="(Slideshow, index) in Slideshows" :key="index">
                <img :src="PostData.SiteUrl + Slideshow.Url" :alt="Slideshow.DisplayName" />
              </div>
            </div>
            <div class="text" v-html="PostData.Post.Content"></div>
            <div class="download-box" v-if="Attachments">
              <h2>檔案下載</h2>
              <ul class="download">
                <li v-for="(Attachment, index) in Attachments" :key="index">
                  <a :href="PostData.SiteUrl +
                    '/Platform/Html/Download?FId=' +
                    Attachment.FId
                    " target="_blank" :title="'下載' + Attachment.DisplayName + ', 另開新視窗'">{{ Attachment.DisplayName
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="text" v-if="PostData.Post.Type == 3">
            <div class="font-box">
              <div class="container">
                <div class="box" v-if="PostData.Form.HeaderContent" v-html="PostData.Form.HeaderContent"></div>
                <form id="frmFormRender">
                  <input id="PId" name="PId" type="hidden" :value="PostData.Post.PId" />
                  <input id="FId" name="FId" type="hidden" :value="PostData.Post.FId" />
                  <input id="serialize" name="serialize" type="hidden" :value="PostData.Form.Serialize" />
                  <input id="serializeData" name="serializeData" type="hidden" value="" />
                  <div id="divForm" style="font-size: 1rem; line-height: 32px"></div>
                  <div class="box">
                    <h2>驗證碼</h2>
                    <div class="form-group">
                      <div class="code-box">
                        <input class="form-control" id="identifyCode_input" placeholder="驗證碼"
                          v-model="identifyCode_input" />
                        <input id="identifyCode" name="identifyCode" type="hidden" v-model="identifyCode" />
                        <div class="code">
                          <img :src="identifyCodeData" width="100" height="30" />
                          <a href="javascript:void(0)" @click="refreshCode()"><img src="/img/icon-rotate.svg"
                              alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-box">
                    <button type="submit">
                      送　出
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Sidentify from "@/components/Identify";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
  name: "Post",
  components: {
    Head,
    Footer,
    Sidentify,
  },
  data() {
    return {
      PostData: null,
      Attachments: null,
      Slideshows: null,
      Verify: false,
      identifyCode: "0511",
      identifyCode_input: "",
      identifyCodeData: "",
      //identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      //identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
      num: 0,
      formLoaded: false,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: this.$route.params.PId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.refreshCode();
  },
  mounted: function () { },
  updated: function () {
    if (!this.formLoaded) {
      let that = this;
      if ($("#frmFormRender").length > 0) {
        var fbRender = $("#divForm").formRender({
          dataType: "json",
          formData: $("#serialize").val(),
          i18n: {
            locale: "zh-TW",
            location: "./js/form-builder/",
            extension: ".lang",
            override: {
              "zh-TW": {
                close: "收起編輯區",
                required: "必填",
                label: "標題",
                labelEmpty: "標題欄位不能是空白的",
                min: "最小值",
                max: "最大值",
                placeholder: "提示文字",
                password: "密碼",
                email: "電子郵件地址",
                checkboxGroup: "多選選單",
                radioGroup: "單選選單",
                select: "下拉選單",
                number: "數字欄位",
                fileUpload: "檔案上傳欄位",
                header: "標題",
                paragraph: "文字段落(僅顯示)",
                remove: "刪除",
                enableOther: "啟用「其它」選項",
                enableOtherMsg:
                  "使用者可自行輸入選項文字" /*'允許使用者填寫自定義選項',*/,
                inline: "單行顯示",
                inlineDesc: "以單行顯示項目內容",
                value: "預設值",
                rows: "預設行數",
                description: "說明",
                maxlength: "字數設定",
              },
            },
          },
        });

        $("#divForm select").each(function () {
          var $this = $(this);
          if ($this.data("relatedto")) {
            // 設定一次預設顯示
            $("#" + $this.attr("id") + " option")
              .hide()
              .filter(
                '[value^="' + $("#" + $this.data("relatedto")).val() + ':"]'
              )
              .show();
            if (
              $(
                "#" +
                $this.attr("id") +
                ' option[value^="' +
                $("#" + $this.data("relatedto")).val() +
                ':"]'
              ).length
            ) {
              $(
                "#" +
                $this.attr("id") +
                ' option[value^="' +
                $("#" + $this.data("relatedto")).val() +
                ':"]'
              )
                .first()
                .prop("selected", true);
            }
            // 再處理變動時的顯示
            $("#" + $this.data("relatedto")).change(function () {
              $("#" + $this.attr("id") + " option")
                .hide()
                .filter('[value^="' + $(this).val() + ':"]')
                .show();
              if (
                $(
                  "#" +
                  $this.attr("id") +
                  ' option[value^="' +
                  $(this).val() +
                  ':"]'
                ).length
              ) {
                $(
                  "#" +
                  $this.attr("id") +
                  ' option[value^="' +
                  $(this).val() +
                  ':"]'
                )
                  .first()
                  .prop("selected", true);
              }
            });
          }
        });

        $("#divForm")
          .find(":file")
          .each(function () {
            var fileId = $(this).attr("id");
            $(this)
              .parent()
              .append(
                "<input type='hidden' id='" +
                fileId +
                "_fileUrl' name='" +
                fileId +
                "_fileUrl' value=''>"
              );
            $(this).on("change", function () {
              var file = this.files[0];
              if (this.files && file) {
                // 可用副檔名
                var re =
                  /(\.png|\.jpg|\.bmp|\.gif|\.doc|\.docx|\.xls|\.xlsx|\.ppt|\.pptx|\.odt|\.ods|\.odp|\.pdf|\.zip|\.rar|\.7z|\.avi|\.mp4|\.wmv|\.mov|\.flv|\.csv)$/i;
                // 判斷副檔名是否正確
                if (file.size >= 629145600) {
                  // 顯示警告
                  alert("檔案過大(>600MB)無法上傳");
                  $(this).val("");
                } else if (!re.exec(file.name)) {
                  // 顯示警告
                  alert("檔案類型錯誤無法上傳");
                  $(this).val("");
                } else {
                  Swal.fire({
                    title: "上傳中",
                    html: '<span id="progress_msg">請稍候...</span>',
                    onBeforeOpen: function () {
                      Swal.showLoading();
                    },
                    showConfirmButton: false,
                    allowOutsideClick: false,
                  });
                  var formData = new FormData();
                  var input = document.getElementById(fileId);
                  var file = input.files[0];
                  formData.append(file.name, file);
                  $.ajax({
                    type: "post",
                    url:
                      that.$store.state.src +
                      that.$store.state.subDirectory +
                      "/Html/_FileUploadByForm",
                    contentType: false,
                    processData: false,
                    data: formData,
                    xhr: function () {
                      var myXhr = $.ajaxSettings.xhr();
                      if (myXhr.upload) {
                        myXhr.upload.addEventListener(
                          "progress",
                          function (evt) {
                            // 註：nth-child 是從 1 開始
                            if (evt.lengthComputable) {
                              var loaded = evt.loaded / evt.total;
                              if (loaded <= 1) {
                                //console.log(evt.loaded / evt.total)
                                var percent = loaded * 100;
                                $("#progress_msg").html(
                                  Math.floor(percent) + "%"
                                );
                                // 改變介面進度條的百分比
                                //progress_el.setAttribute("style", "width: " + percent + "%;");
                              }
                            }
                          },
                          false
                        );
                      }
                      return myXhr;
                    },
                    success: function (result) {
                      if (result.ok) {
                        $("#" + fileId + "_fileUrl").val(result.FileUrl);
                        Swal.close();
                      } else if (result.error) {
                        $("#" + fileId + "_fileUrl").val("");
                        input.val("");
                        Swal.fire({
                          icon: "error",
                          title: "錯誤",
                          text: result.error,
                          confirmButtonText: "確　　認",
                        });
                        return false;
                      } else {
                        $("#" + fileId + "_fileUrl").val("");
                        input.val("");
                        Swal.fire({
                          icon: "error",
                          title: "發生錯誤，請聯絡資訊人員。",
                          html: result,
                          confirmButtonText: "確　　認",
                        });
                        return false;
                      }
                    },
                    error: function (xhr, status) {
                      $("#" + fileId + "_fileUrl").val("");
                      input.val("");
                      Swal.fire({
                        icon: "error",
                        title: "錯誤",
                        text: "檔案上傳失敗, 請稍候再試一次",
                        confirmButtonText: "確　　認",
                      });
                      return false;
                    },
                  });
                }
              }
            });
          });

        if ($('[type="date"]').prop("type") != "date") {
          $('[type="date"]').datepicker({
            dateFormat: "yy/m/d",
          });
        }

        $("#frmFormRender").parsley();

        $("#frmFormRender")
          .parsley()
          .on("field:validated", function () {
            var e = 0 === $(".parsley-error").length;
            $(".alert-info").toggleClass("d-none", !e),
              $(".alert-warning").toggleClass("d-none", e);
          })
          .on("form:submit", function () {
            $("#serializeData").val(JSON.stringify(fbRender.userData));
            Swal.fire({
              title: "表單上傳中",
              html: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              type: "post",
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/_SendForm",
              data: $("#frmFormRender").serialize(),
              success: function (result) {
                if (result.ok) {
                  Swal.fire({
                    title: "成功",
                    text:
                      "已成功送出, 感謝您的填寫!您的填寫順序為:" +
                      result.Number,
                    type: "success",
                    confirmButtonClass: "btn btn-confirm mt-2",
                    confirmButtonText: "確　　認",
                    // onClose: () => {
                    //   location.reload();
                    // },
                  });
                  fbRender.clear();
                  that.refreshCode();
                  that.identifyCode_input = "";
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                } else {
                  Swal.fire({
                    title: "發生錯誤，請聯絡資訊人員。",
                    html: result,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              },
              error: function () {
                Swal.fire({
                  title: "發生錯誤，請聯絡資訊人員。",
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonText: "確定",
                  heightAuto: false,
                });
              },
            });
            return false;
          });
      }
      this.formLoaded = true;
    }
  },
  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.identifyCodeData = "";
      this.makeCode();
    },
    makeCode() {
      // for (let i = 0; i < l; i++) {
      //   var code =
      //     this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      //   this.identifyCode += code;
      //   this.identifyCode_sound[i] = code + ".mp3";
      // }
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/GetIdentifyCode",
        type: "post",
        data: {
          //token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.identifyCode = res.identifyCode;
            that.identifyCodeData = res.identifyCodeData;
          }
        },
      });
    },
    // playCode() {
    //   var sound_path = "/voice/numvoice/zh";
    //   var audio = new Audio(
    //     sound_path + "/" + this.identifyCode_sound[this.num]
    //   );
    //   audio.play();
    //   let that = this;
    //   audio.addEventListener(
    //     "ended",
    //     function () {
    //       that.num++;
    //       if (that.num < 4) that.playCode();
    //       if (that.num >= 4) that.num = 0;
    //     },
    //     false
    //   );
    // },
  },
};
</script>

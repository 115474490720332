<template>
  <div>
    <Head />
    <!-- 忘記密碼 -->
    <div class="wrapper register forget">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                忘記密碼
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <div class="box">
            <div class="title">
              <h1>忘記密碼</h1>
              <p>請正確填寫您的個人資料以維護您的會員權益。</p>
            </div>
            <div class="form-group">
              <label for="">電子信箱</label>
              <input
                type=""
                class="form-control"
                placeholder="請輸入電子信箱"
                v-model="Email"
                :disabled="pincodeCheck"
              />
            </div>
            <div class="form-group">
              <label for="">手機號碼</label>
              <input
                type=""
                class="form-control"
                placeholder="請輸入手機號碼"
                v-model="Phone"
                :disabled="pincodeCheck"
              />
            </div>
            <div class="form-group">
              <label for="">簡訊驗證</label>
              <div class="code-box">
                <input
                  type=""
                  class="form-control"
                  placeholder="請輸入簡訊驗證"
                  v-model="pincode_input"
                  @input="pincode_input_onInput"
                  :disabled="pincodeCheck"
                />
                <button v-if="!pincodeCheck" @click="getPinCode()">
                  發送檢核碼
                </button>
              </div>
            </div>
            <hr v-if="pincodeCheck" />
            <div class="form-group" v-if="pincodeCheck">
              <label for="">密碼</label>
              <input
                type="password"
                class="form-control"
                placeholder="請輸入密碼"
                v-model="Password"
              />
            </div>
            <div class="form-group" v-if="pincodeCheck">
              <label for="">密碼確認</label>
              <input
                type="password"
                class="form-control"
                placeholder="請再次輸入密碼"
                v-model="PasswordConfirm"
              />
            </div>
            <hr />
            <div class="btn-box" v-if="pincodeCheck">
              <button @click="sendResetPassword()">重設密碼</button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Forget",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Password: "",
      PasswordConfirm: "",
      Email: "",
      Phone: "",
      pincode_input: "",
      pincode: "",
      pincodeCheck: false,
      pincodeTitle: "發送檢核碼",
      pincodeTime: 0,
      pincode_Phone: "",
    };
  },
  watch: {
    Phone(newVal, oldVal) {
      if (newVal != this.pincode_Phone) {
        this.pincode = "";
        this.pincodeCheck = false;
      }
    },
  },
  beforeCreate: function () {
    if (this.$store.state.User) {
      this.$router.push({
        name: "home",
      });
    }
  },
  created: function () {
    // Vue.axios
    //   .get(
    //     this.$store.state.src +
    //       this.$store.state.subDirectory +
    //       "/Html/CaseAdd",
    //     {
    //       params: {
    //         CTIId: this.$route.params.CTIId,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     this.CaseAddData = response.data;
    //     if (this.CaseAddData.CaseType.VerifiedType != 1) {
    //       this.Sugg_ReplyType = this.CaseAddData.CaseType.VerifiedType;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  mounted: function () {},
  methods: {
    pincode_input_onInput() {
      if (this.pincode_input != "" && this.pincode_input == this.pincode) {
        this.pincodeCheck = true;
      } else {
        this.pincodeCheck = false;
      }
    },
    getPinCode() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_phone(this.Phone)) {
        Swal.fire({
          title: "錯誤",
          text: "手機號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 組合並送出
      let token = AES.encrypt("2|" + this.Phone + "|");
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetCheckNumber",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.issned = true;
            var strInfo = AES.decrypt(res.PinCode).split("|");
            that.pincode = strInfo[0];
            that.pincode_Phone = strInfo[2];
            // 倒數
            that.pincodeTime = 60;
            //that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
            var loop = window.setInterval(function () {
              that.pincodeTime -= 1;
              //that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
              const span = Swal.getHtmlContainer().querySelector("span");
              if (span) {
                span.textContent = that.pincodeTime;
              }
              if (that.pincodeTime == 0) {
                //that.pincodeTitle = "發送檢核碼";
                window.clearInterval(loop);
              }
            }, 1000);
            Swal.fire({
              title: "已發送驗證碼",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    sendResetPassword() {
      if (this.Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入帳號",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Email)) {
        Swal.fire({
          title: "錯誤",
          text: "帳號格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_phone(this.Phone)) {
        Swal.fire({
          title: "錯誤",
          text: "手機號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Password == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (this.PasswordConfirm != this.Password) {
        Swal.fire({
          title: "錯誤",
          text: "兩次輸入密碼必須相同",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 開始送出
      let that = this;
      let token = AES.encrypt(
        this.Email + "|" + this.Phone + "|" + this.Password
      );
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ResetPassword",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "重設密碼完成",
              text: "即將返回首頁",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 3000,
              willClose: () => {
                that.$router.push({
                  name: "home",
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            if (res.reset) {
              that.pincodeCheck = false;
              that.pincode = "";
              that.pincode_input = "";
              that.pincode_Phone = "";
              that.PasswordConfirm = "";
              that.Password = "";
            }
          }
        },
      });
    },
  },
};
</script>


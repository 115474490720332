<template>
  <div>
    <Head />

    <div class="wrapper home" v-if="HomeData">
      <!-- banner -->
      <section class="banner" v-if="HomeData.Banner" style="z-index: 1">
        <slick class="single-item" :options="{
          autoplay: true,
          autoplaySpeed: 5000,
        }">
          <div class="box" v-for="Post in HomeData.Banner.Posts" :key="Post.PId">
            <a @click="gotoPost(Post)"><img :src="HomeData.SiteUrl + Post.Image" alt="" /></a>
          </div>
        </slick>
      </section>

      <!-- 中間部分 -->
      <section class="main">
        <!-- 公告 -->
        <div class="notice" v-if="HomeData.Marquee">
          <div class="box">
            <h1>{{ HomeData.Marquee.Category.CategoryName }}</h1>
            <slick class="text-box" :options="{
              arrows: false,
              vertical: true,
              autoplay: true,
              autoplaySpeed: 3000,
            }">
              <div v-for="Post in HomeData.Marquee.Posts" :key="Post.PId">
                <a href="javascript:void(0)" @click="gotoPost(Post)">
                  <p>{{ Post.Title }}</p>
                </a>
              </div>
            </slick>
          </div>
        </div>
        <div class="">
          <!-- 大框框 -->
          <div class="box">
            <!-- 新增案件 -->
            <div class="new-case" type="button" @click="callCaseAdd()">
              <button>
                <h1>新增通報</h1>
              </button>
            </div>
            <!-- 撐寬度用 -->
            <div></div>
            <!-- 三個按鈕 -->
            <div class="btn-box" v-if="HomeData.QA">
              <a class="telephone" href="javascript:void(0)" type="button" data-toggle="modal"
                data-target="#phoneModal">
                <img src="/img/telephone-bg.png" alt="" />
              </a>
              <a v-for="(MenuData, index) in HomeData.QA" :key="index" href="javascript:void(0)"
                @click="gotoMenu(MenuData.Menu)">
                <img :src="HomeData.SiteUrl + MenuData.Menu.Image" alt="" />
              </a>
            </div>
            <!-- 撐寬度用 -->
            <div></div>
            <!-- 案件編號查詢 -->
            <div class="search-box">
              <div class="title">
                <h1>案件編號查詢</h1>
              </div>
              <div class="form-group">
                <label for="searchType" style="background-image: url(/img/search-icon2.svg)">查詢方式</label>
                <select class="form-control" id="searchType" v-model="searchType">
                  <option value="1" selected>手機門號搜尋</option>
                  <option value="2">信箱搜尋</option>
                  <option value="3">姓名搜尋</option>
                </select>
              </div>
              <div class="form-group">
                <label for="bySystem" style="background-image: url(/img/search-icon3.svg)">案件編號</label>
                <div class="case-number">
                  <select class="form-control" id="bySystem" v-model="bySystem">
                    <option value="A-TB" selected>A-TB</option>
                    <option value="B">B</option>
                  </select>
                  <div class="time-box">
                    <select class="form-control" id="year" v-if="bySystem == 'A-TB'" v-model="year">
                      <option v-for="index in NowYear - 2014" :key="index">
                        {{ NowYear - (index - 1) }}
                      </option>
                    </select>
                    <hr v-if="bySystem == 'A-TB'" />
                    <input type="text" class="form-control" placeholder="請輸入案件編號" v-model="caseNo" />
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="searchType == 1">
                <label for="phone" style="background-image: url(/img/search-icon4.svg)">手機門號</label>
                <input type="" class="form-control" id="phone" placeholder="手機門號" v-model="phone" />
              </div>
              <div class="form-group" v-if="searchType == 2">
                <label for="email" style="background-image: url(/img/search-icon4.svg)">信箱</label>
                <input type="" class="form-control" id="email" placeholder="信箱" v-model="email" />
              </div>
              <div class="form-group" v-if="searchType == 3">
                <label for="phone" style="background-image: url(/img/search-icon4.svg)">姓名</label>
                <input type="" class="form-control" id="username" placeholder="姓名" v-model="username" />
              </div>
              <div class="form-group">
                <label style="background-image: url(/img/search-icon5.svg)">驗證碼</label>
                <div class="code-box">
                  <input class="form-control" placeholder="驗證碼" v-model="identifyCode_input" />
                  <div class="code">
                    <img :src="identifyCodeData" width="100" height="30" />
                    <a href="javascript:void(0)" @click="refreshCode()"><img src="/img/icon-rotate.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <div class="btn-box">
                <button @click="caseOneQry()">
                  搜尋
                </button>
              </div>
            </div>
            <!-- 圖表 1999各時段進線量概況 -->
            <div class="img-box" v-if="HomeData.Chart_A">
              <div class="title">
                <h1>1999各時段進線量概況</h1>
              </div>
              <div class="img">
                <img :src="HomeData.Chart_A" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 圖表2 1999每月總進線量概況 -->
      <section class="form-img" v-if="HomeData.Chart_B">
        <!-- <div class="">
          <div class="title">
            <h1>1999每月總進線量概況</h1>
          </div>
          <div class="box">
            <img :src="HomeData.Chart_B" alt="" />
          </div>
        </div> -->
        <!-- 1214 新的長條圖和切換分類 -->
        <div class="container">
          <div class="title-box">
            <div>
              <button @click="changeTimeType('M')" :class="timeType == 'M' ? 'active' : ''" id="btnM">
                每月案件
              </button>
              <button @click="changeTimeType('W')" :class="timeType == 'W' ? 'active' : ''" id="btnW">
                每週案件
              </button>
              <button @click="changeTimeType('D')" :class="timeType == 'D' ? 'active' : ''" id="btnD">
                每日案件
              </button>
            </div>
            <div>
              <div class="total show" v-if="timeType == 'M'">
                <p>本月</p>
                <p class="number">
                  {{ CaseStatisticsData.M_Count }} <span>件</span>
                </p>
                <p>
                  {{ CaseStatisticsData.M_Start }} ~
                  {{ CaseStatisticsData.M_End }}
                </p>
              </div>
              <div class="total show" v-if="timeType == 'W'">
                <p>本週</p>
                <p class="number">
                  {{ CaseStatisticsData.W_Count }} <span>件</span>
                </p>
                <p>
                  {{ CaseStatisticsData.W_Start }} ~
                  {{ CaseStatisticsData.W_End }}
                </p>
              </div>
              <div class="total show" v-if="timeType == 'D'">
                <p>本日</p>
                <p class="number">
                  {{ CaseStatisticsData.D_Count }} <span>件</span>
                </p>
                <p>
                  {{ CaseStatisticsData.D_Start }} ~
                  {{ CaseStatisticsData.D_End }}
                </p>
              </div>
            </div>
          </div>
          <div class="note">
            案件通報的九大類別每月通報數量顯示，顯示方式採用長條圖數據呈現
          </div>
        </div>
        <div id="chartdiv_M" class="chartdiv" :style="timeType != 'M' ? 'display: none;' : ''"></div>
        <div id="chartdiv_W" class="chartdiv" :style="timeType != 'W' ? 'display: none;' : ''"></div>
        <div id="chartdiv_D" class="chartdiv" :style="timeType != 'D' ? 'display: none;' : ''"></div>
      </section>

      <!-- 相關連結 -->
      <section class="link-box" v-if="HomeData.Links">
        <div class="container">
          <h1>相關連結</h1>
          <div class="box">
            <div class="img-box" v-for="(MenuData, index) in HomeData.Links" :key="index">
              <a v-if="MenuData.Menu.Type == 0" href="javascript:void(0)"><img
                  :src="HomeData.SiteUrl + MenuData.Menu.Image" alt="" /></a>
              <a v-if="MenuData.Menu.Type == 1" href="javascript:void(0)"
                @click="gotoCategoryByCId(MenuData.Menu.CId)"><img :src="HomeData.SiteUrl + MenuData.Menu.Image"
                  alt="" /></a>
              <a v-if="MenuData.Menu.Type == 2" href="javascript:void(0)" @click="gotoPostByPId(MenuData.Menu.PId)"><img
                  :src="HomeData.SiteUrl + MenuData.Menu.Image" alt="" /></a>
              <a v-if="MenuData.Menu.Type == 3" href="javascript:void(0)"
                @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)"><img
                  :src="HomeData.SiteUrl + MenuData.Menu.Image" alt="" /></a>
            </div>
          </div>
        </div>
      </section>
      <!-- 新增案件 彈跳視窗 -->
      <div class="new-case-box" type="button" data-toggle="modal" data-target="#caseModal">
        <div class="modal fade" id="caseModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
          style="padding-left: 0px">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="title">
                <h5 class="">臺南線上即時通報系統</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="">
                <ul class="case-btn">
                  <li v-for="(CaseTypeItem, index) in HomeData.CaseTypeItems" :key="index">
                    <a href="javascript:void(0)" @click="newCase(CaseTypeItem.CaseType)">
                      <img :src="HomeData.SiteUrl + CaseTypeItem.CaseType.Image" alt="" />
                      <p>{{ CaseTypeItem.CaseType.Title }}</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="text">
                <p>
                  民眾通報案件由權責機關於期限內回復，各類案件回復天數如<a
                    href="https://1999.tainan.gov.tw/Upload/FileManager/94effcd3-1dd7-4004-a079-bc0d875fbcbe.pdf"
                    target="_blank">附表</a>。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 撥打電話 彈跳視窗 -->
    <div class="login-box telephone-btn" type="button" data-toggle="modal" data-target="#phoneModal">
      <div class="modal fade" id="phoneModal" tabindex="-1" aria-hidden="true" style="padding-left: 0px">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="width: 800px">
            <div class="title">
              <h5 class="">撥打電話</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <div class="btn-box">
                <button onclick="location.href='tel:1999'">
                  撥打電話<br /><span style="font-size: 1rem">手機使用</span>
                </button>
                <button onclick="window.open('https://webcall.tainan.gov.tw/webcall/webcall.html')">
                  Webcall<br /><span style="font-size: 1rem">電腦跟安卓裝置</span>
                </button>
                <button onclick="window.open('https://www.juiker.tw/download')">
                  Juiker<br /><span style="font-size: 1rem">iOS使用</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { VueRecaptcha } from "vue-recaptcha";
import Slick from "vue-slick";
import Sidentify from "@/components/Identify";

Vue.use(VueAxios, axios);

export default {
  name: "Home",
  components: {
    Head,
    Footer,
    VueRecaptcha,
    Slick,
    Sidentify,
  },
  data() {
    return {
      HomeData: {},
      NowYear: new Date().getFullYear(),
      searchType: 1,
      bySystem: "A-TB",
      year: new Date().getFullYear(),
      caseNo: "",
      phone: "",
      email: "",
      username: "",
      Verify: false,
      identifyCode: "0511",
      identifyCode_input: "",
      identifyCodeData: "",
      //identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      //identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
      num: 0,
      timeType: "M",
      CaseStatisticsData: {},
      chart: null,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: {},
        }
      )
      .then((response) => {
        this.HomeData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    this.refreshCode();
  },
  mounted: function () {
    // 首頁 手機板選單按鈕切換
    $(".navbar-toggler").click(function () {
      $(".navbar-toggler span").toggleClass("add");
    });

    let that = this;

    // 1018 首頁 圖表 動畫
    $("html body").scroll(function () {
      var N = $("html body").scrollTop();
      if (
        $(".home .main .img-box") &&
        $(".home .main .img-box").offset() &&
        that.HomeData.Chart_A
      ) {
        var B = $(".home .main .img-box").offset().top - 300;
        if (N > B) {
          $(".home .main .img-box").addClass("add");
        } else {
          $(".home .main .img-box").removeClass("add");
        }
      }
      if (
        $(".home .form-img") &&
        $(".home .form-img").offset() &&
        that.HomeData.Chart_B
      ) {
        var V = $(".home .form-img").offset().top + 300;
        if (N > V) {
          $(".home .form-img > div").addClass("add");
        } else {
          $(".home .form-img > div").removeClass("add");
        }
      }
    });

    // // 首頁 banner
    // $(".single-item").slick({
    //   autoplay: true,
    //   autoplaySpeed: 5000,
    // });

    // 首頁 公告
    // $(".notice .text-box").slick({
    //   arrows: false,
    //   vertical: true,
    //   autoplay: true,
    //   autoplaySpeed: 3000,
    // });

    $.ajax({
      url:
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/GetCaseStatisticsForHome",
      type: "post",
      data: {},
      dataType: "json",
      error: function () { },
      success: function (res) {
        that.CaseStatisticsData = res;
        that.changeTimeType("M");
        AmCharts.theme = AmCharts.themes.light;
        AmCharts.makeChart("chartdiv_M", that.CaseStatisticsData.M);
        AmCharts.makeChart("chartdiv_W", that.CaseStatisticsData.W);
        AmCharts.makeChart("chartdiv_D", that.CaseStatisticsData.D);
      },
    });
  },
  updated: function () {
    setTimeout(function () {
      // 首頁 相關連結
      $(".link-box .box").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,

        responsive: [
          {
            breakpoint: 1199.98,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991.98,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767.98,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }, 1000);
  },
  methods: {
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Url != "#") {
          if (Post.Blank) {
            window.open(Post.Url, "_blank");
          } else {
            window.open(Post.Url);
          }
        }
      } else {
        this.$router.push({
          name: "post",
          params: { PId: Post.PId },
        });
      }
    },
    gotoMenu(Menu) {
      if (Menu.Type == 1) {
        this.$router.push({
          name: "category",
          params: { CId: Menu.CId },
        });
      }
      if (Menu.Type == 2) {
        this.$router.push({
          name: "post",
          params: { PId: Menu.PId },
        });
      }
      if (Menu.Type == 3) {
        if (Menu.Url != "#") {
          if (Menu.Blank) {
            window.open(Menu.Url, "_blank");
          } else {
            location.href = Menu.Url;
          }
        }
      }
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    searchTypeChange: function (event) {
      this.SearchType = event.target.value;
    },
    bySystemChange: function (event) {
      this.BySystem = event.target.value;
    },
    caseOneQry: function () {
      if (this.caseNo == "") {
        Swal.fire({
          icon: "error",
          title: "錯誤",
          text: "請輸入案件編號",
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.searchType == 1 && this.phone == "") {
        Swal.fire({
          icon: "error",
          title: "錯誤",
          text: "請輸入手機門號",
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.searchType == 2 && this.email == "") {
        Swal.fire({
          icon: "error",
          title: "錯誤",
          text: "請輸入信箱",
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.searchType == 3 && this.username == "") {
        Swal.fire({
          icon: "error",
          title: "錯誤",
          text: "請輸入姓名",
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 開始送出
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ChaekIdentifyCode",
        type: "post",
        data: {
          identifyCode_input: that.identifyCode_input,
          identifyCode: that.identifyCode,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            var caseNo_full = that.bySystem;
            if (that.bySystem == "A-TB") {
              caseNo_full += "-" + that.year;
            }
            caseNo_full += "-" + that.caseNo;
            if (that.searchType == 1) {
              that.$router.push({
                name: "caseDetail",
                query: {
                  caseNo: caseNo_full,
                  sugg_telno: that.phone,
                },
              });
            } else if (that.searchType == 2) {
              that.$router.push({
                name: "caseDetail",
                query: {
                  caseNo: caseNo_full,
                  sugg_email: that.email,
                },
              });
            } else if (that.searchType == 3) {
              that.$router.push({
                name: "caseDetail",
                query: {
                  caseNo: caseNo_full,
                  sugg_name: that.username,
                },
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "錯誤",
                text: "查詢方式不存在",
                confirmButtonText: "確定",
              });
              return;
            }
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    newCase: function (CaseType) {
      $("#caseModal").modal("toggle");
      if (CaseType.IsPopup) {
        let that = this;
        Swal.fire({
          width: 800,
          imageUrl: this.HomeData.SiteUrl + CaseType.Image,
          imageWidth: 100,
          imageHeight: 100,
          title: CaseType.Title,
          html: CaseType.PopupContent,
          confirmButtonText: "確定",
          heightAuto: false,
          willClose: () => {
            if (CaseType.Type == 2) {
              if (CaseType.Blank) {
                window.open(CaseType.Url);
              } else {
                location.href = CaseType.Url;
              }
            } else {
              that.$router.push({
                name: "caseAdd",
                params: {
                  CTIId: CaseType.CTIId,
                },
              });
            }
          },
        });
      } else {
        if (CaseType.Type == 2) {
          if (CaseType.Blank) {
            window.open(CaseType.Url);
          } else {
            location.href = CaseType.Url;
          }
        } else {
          this.$router.push({
            name: "caseAdd",
            params: {
              CTIId: CaseType.CTIId,
            },
          });
        }
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.identifyCodeData = "";
      this.makeCode();
    },
    makeCode() {
      // for (let i = 0; i < l; i++) {
      //   var code =
      //     this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      //   this.identifyCode += code;
      //   this.identifyCode_sound[i] = code + ".mp3";
      // }
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/GetIdentifyCode",
        type: "post",
        data: {
          //token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.identifyCode = res.identifyCode;
            that.identifyCodeData = res.identifyCodeData;
          }
        },
      });
    },
    // playCode() {
    //   var sound_path = "/voice/numvoice/zh";
    //   var audio = new Audio(
    //     sound_path + "/" + this.identifyCode_sound[this.num]
    //   );
    //   audio.play();
    //   let that = this;
    //   audio.addEventListener(
    //     "ended",
    //     function () {
    //       that.num++;
    //       if (that.num < 4) that.playCode();
    //       if (that.num >= 4) that.num = 0;
    //     },
    //     false
    //   );
    // },
    changeTimeType(timeType) {
      this.timeType = timeType;
    },
    callCaseAdd() {
      let that = this;
      if (that.HomeData.CaseAddPopup) {
        Swal.fire({
          width: 800,
          title: that.HomeData.CaseAddPopupTitle,
          html: that.HomeData.CaseAddPopupContent,
          confirmButtonText: "確定",
          heightAuto: false,
          willClose: () => {
            $("#caseModal").modal("show");
          },
        });
      } else {
        $("#caseModal").modal("show");
      }
    },
  },
};
</script>

<template>
  <div>
    <Head />

    <div class="wrapper member">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                案件編號查詢
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main" v-if="ErrorMsg">
        <div class="container" style="justify-content: center">
          <h2>{{ ErrorMsg }}</h2>
        </div>
      </section>
      <section class="main" v-if="CaseContent">
        <div class="container">
          <!-- 右邊框框 -->
          <div class="box" style="display: block">
            <!-- 全部案件1 -->
            <ul class="case-box show">
              <!-- 第二個案件 -->
              <li>
                <!-- 大img -->
                <div class="circle">
                  <div>
                    <img
                      class="present"
                      src="/img/case-progress1.svg"
                      alt=""
                      v-if="CaseContent.CaseStep == 1"
                    />
                    <img
                      class="present"
                      src="/img/case-progress3.svg"
                      alt=""
                      v-if="CaseContent.CaseStep == 2"
                    />
                    <img
                      class="present"
                      src="/img/case-progress5.svg"
                      alt=""
                      v-if="CaseContent.CaseStep == 3"
                    />
                  </div>
                </div>
                <!-- 標題 -->
                <div class="title">
                  <h2>案件編號：{{ CaseContent.CaseNo }}</h2>
                  <!-- <p v-if="CaseContent.Organizer">
                    承辦單位：{{ CaseContent.Organizer }}
                  </p> -->
                </div>
                <!-- 文字 -->
                <div class="text-box">
                  <div v-if="CaseContent.AppealTime">
                    <p>立案日期</p>
                    ：
                    <p>{{ CaseContent.AppealTime }}</p>
                  </div>
                  <div v-if="CaseContent.FinishTime">
                    <p>結案日期</p>
                    ：
                    <p>{{ CaseContent.FinishTime }}</p>
                  </div>
                  <div v-if="CaseContent.Area">
                    <p>行政區</p>
                    ：
                    <p>{{ CaseContent.Area }}</p>
                  </div>
                  <div v-if="CaseContent.Location">
                    <p>地點</p>
                    ：
                    <p>{{ CaseContent.Location }}</p>
                  </div>
                  <div v-if="CaseContent.Type">
                    <p>類型</p>
                    ：
                    <p>{{ CaseContent.Type }}</p>
                  </div>
                  <div v-if="CaseContent.SubType">
                    <p>事項</p>
                    ：
                    <p>{{ CaseContent.SubType }}</p>
                  </div>
                </div>
                <!-- 進度條 -->
                <ul class="progress-box">
                  <li>
                    <div
                      class="circle"
                      :class="ShowTab_Style == 1 ? 'active' : ''"
                      @click="
                      {
                        ShowTab = 1;
                        ShowTab_Style = 1;
                      }"
                    >
                      <div :style="CaseContent.CaseStep < 1 ? 'background: #b5b5b5;' : ''">
                        <img src="/img/case-progress1.svg" alt="" />
                      </div>
                    </div>
                    <p>通報</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="ShowTab_Style == 2 ? 'active' : ''"
                      @click="
                      {
                        ShowTab = 1;
                        ShowTab_Style = 2;
                      }"
                    >
                      <div :style="CaseContent.CaseStep < 1 ? 'background: #b5b5b5;' : ''">
                        <img src="/img/case-progress2.svg" alt="" />
                      </div>
                    </div>
                    <p>立案</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="ShowTab_Style == 3 ? 'active' : ''"
                      @click="
                      {
                        ShowTab = 2;
                        ShowTab_Style = 3;
                      }"
                    >
                      <div :style="CaseContent.CaseStep < 2 ? 'background: #b5b5b5;' : ''">
                        <img src="/img/case-progress3.svg" alt="" />
                      </div>
                    </div>
                    <p>分案</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="ShowTab_Style == 4 ? 'active' : ''"
                      @click="
                      {
                        ShowTab = 2;
                        ShowTab_Style = 4;
                      }"
                    >
                      <div :style="CaseContent.CaseStep < 2 ? 'background: #b5b5b5;' : ''">
                        <img src="/img/case-progress4.svg" alt="" />
                      </div>
                    </div>
                    <p>處理情形</p>
                  </li>
                  <li>
                    <div
                      class="circle"
                      :class="ShowTab_Style == 5 ? 'active' : ''"
                      @click="
                      {
                        ShowTab = 3;
                        ShowTab_Style = 5;
                      }"
                    >
                      <div :style="CaseContent.CaseStep < 3 ? 'background: #b5b5b5;' : ''">
                        <img src="/img/case-progress5.svg" alt="" />
                      </div>
                    </div>
                    <p>結案</p>
                  </li>
                </ul>

                <div
                  class="detail"
                  :class="ShowTab == 1 ? 'show' : ''"
                  v-if="Step1"
                >
                  <div v-for="(Step, index) in Step1" :key="index">
                    <div class="title-box">
                      <h3>{{ Step.Title }}</h3>
                      <p>{{ Step.DateTime }}</p>
                    </div>
                    <div class="text">
                      <p style="white-space: pre-line">
                        {{ Step.Detail }}
                      </p>
                      <div v-if="Step.State">
                        <p>案件辦理情形</p>
                        <p style="white-space: pre-line">{{ Step.State }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="detail"
                  :class="ShowTab == 2 ? 'show' : ''"
                  v-if="Step2"
                >
                  <div
                    v-for="(Step, index) in Step2"
                    :key="index"
                    style="margin-bottom: 15px"
                  >
                    <div class="title-box">
                      <!-- <h3>{{ Step.Title }}</h3> -->
                      <h3 v-if="ShowTab_Style == 3">分案</h3>
                      <h3 v-if="ShowTab_Style == 4">處理情形</h3>
                      <p>{{ Step.DateTime }}</p>
                    </div>
                    <div class="text">
                      <p style="white-space: pre-line" v-if="ShowTab_Style == 4">
                        {{ Step.Detail }}
                      </p>
                      <div v-if="Step.Organizer">
                        <p>承辦單位</p>
                        <p>{{ Step.Organizer }}</p>
                      </div>
                    </div>
                    <div class="title-box" v-if="Step.Atth_FileNames">
                      <h3>附件</h3>
                    </div>
                    <div class="text" v-if="Step.Atth_FileNames">
                      <div
                        v-for="(Atth_FileName, index_f) in Step.Atth_FileNames"
                        :key="index_f"
                      >
                        <p>附件 {{ index_f + 1 }}</p>
                        <p>
                          <a
                            :href="
                              'https://cmsweb.tainan.gov.tw/WebApi2/api/attachfile/' +
                              Atth_FileName.SerialNo +
                              '?fileName=' +
                              Atth_FileName.FileName +
                              '&caseNo=' +
                              CaseContent.CaseNo +
                              '&suggName=' +
                              Step.Sugg_name +
                              '&organNo=' +
                              Step.OrganNo +
                              '&seqNo=' +
                              Step.SeqNo +
                              '&sNo=' +
                              Step.Sno
                            "
                            traget="_blank"
                            >{{ Atth_FileName.FileName }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="detail"
                  :class="ShowTab == 3 ? 'show' : ''"
                  v-if="Step3"
                >
                  <div v-for="(Step, index) in Step3" :key="index">
                    <div class="title-box">
                      <h3>{{ Step.Title }}</h3>
                      <p>{{ Step.DateTime }}</p>
                    </div>
                    <div class="text">
                      <p style="white-space: pre-line">
                        {{ Step.Detail }}
                      </p>
                      <!-- <div v-if="Step.State">
                        <p>案件辦理情形</p>
                        <p>{{ Step.State }}</p>
                      </div> -->
                      <div v-if="Step.Organizer">
                        <p>承辦單位</p>
                        <p>{{ Step.Organizer }}</p>
                      </div>
                    </div>
                    <div class="title-box" v-if="Step.Atth_FileNames">
                      <h3>附件</h3>
                    </div>
                    <div class="text" v-if="Step.Atth_FileNames">
                      <div
                        v-for="(Atth_FileName, index_f) in Step.Atth_FileNames"
                        :key="index_f"
                      >
                        <p>附件 {{ index_f + 1 }}</p>
                        <p>
                          <a
                            :href="
                              'https://cmsweb.tainan.gov.tw/WebApi2/api/attachfile/' +
                              Atth_FileName.SerialNo +
                              '?fileName=' +
                              Atth_FileName.FileName +
                              '&caseNo=' +
                              CaseContent.CaseNo +
                              '&suggName=' +
                              Step.Sugg_name +
                              '&organNo=' +
                              Step.OrganNo +
                              '&seqNo=' +
                              Step.SeqNo +
                              '&sNo=' +
                              Step.Sno
                            "
                            traget="_blank"
                            >{{ Atth_FileName.FileName }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div style="display: none">
        {{ JsonData }}
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
  name: "CaseSearch",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      //ContentData: null,
      //ProcInfoData: null,
      CaseContent: null,
      Step1: null,
      Step2: null,
      Step3: null,
      ErrorMsg: "",
      ShowTab: 0,
      ShowTab_Style: 0,
      JsonData: "",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseOneQry",
        {
          params: {
            caseNo: this.$route.query.caseNo,
            sugg_telno: this.$route.query.sugg_telno,
            sugg_email: this.$route.query.sugg_email,
            sugg_name: this.$route.query.sugg_name,
          },
        }
      )
      .then((response) => {
        this.ErrorMsg = response.data.ErrorMsg;
        this.CaseContent = response.data.CaseContent;
        this.Step1 = response.data.Step1;
        this.Step2 = response.data.Step2;
        this.Step3 = response.data.Step3;
        this.ShowTab = this.CaseContent.CaseStep;

        if (this.ShowTab == 1) {
          this.ShowTab_Style = 2;
        } else if (this.ShowTab == 2) {
          this.ShowTab_Style = 4;
        } else if (this.ShowTab == 3) {
          this.ShowTab_Style = 5;
        }

        this.JsonData = response.data.JsonData;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {},
  methods: {
    convertDatetimeString(time) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>


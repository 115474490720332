<template>
  <div>
    <!-- 頭 -->
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <router-link to="/" class="navbar-brand">
          <img src="/img/Logo.svg" alt="" v-if="!HeadData || !HeadData.SiteLogo" />
          <img :src="HeadData.SiteLogo" alt="" v-if="HeadData && HeadData.SiteLogo" />
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="line1"></span>
          <span class="line2"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="menu-box" v-if="HeadData">
            <ul class="sidebar" v-if="HeadData.RightTop_Menu">
              <li>
                <a href=""><img src="/img/icon-circle.svg" alt="" /></a>
              </li>
              <li v-for="(MenuData, index) in HeadData.RightTop_Menu" :key="index">
                <a v-if="MenuData.Menu.Type == 0" href="javascript:void(0)">{{
                  MenuData.Menu.Title
                  }}</a>
                <a v-if="MenuData.Menu.Type == 1" href="javascript:void(0)"
                  @click="gotoCategoryByCId(MenuData.Menu.CId)">{{ MenuData.Menu.Title }}</a>
                <a v-if="MenuData.Menu.Type == 2" href="javascript:void(0)" @click="gotoPostByPId(MenuData.Menu.PId)">{{
                  MenuData.Menu.Title }}</a>
                <a v-if="MenuData.Menu.Type == 3" href="javascript:void(0)"
                  @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
              </li>
            </ul>
            <ul class="navbar-nav" v-if="HeadData.Header_Menu">
              <li class="nav-item" v-for="(MenuData, index) in HeadData.Header_Menu" :key="index">
                <a v-if="MenuData.Menu.Type == 0" class="nav-link" href="javascript:void(0)">{{ MenuData.Menu.Title
                  }}</a>
                <a v-if="MenuData.Menu.Type == 1" class="nav-link" href="javascript:void(0)"
                  @click="gotoCategoryByCId(MenuData.Menu.CId)">{{ MenuData.Menu.Title }}</a>
                <a v-if="MenuData.Menu.Type == 2" class="nav-link" href="javascript:void(0)"
                  @click="gotoPostByPId(MenuData.Menu.PId)">{{ MenuData.Menu.Title }}</a>
                <a v-if="MenuData.Menu.Type == 3" class="nav-link" href="javascript:void(0)"
                  @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
              </li>
              <div class="login" style="margin-top: -2px">
                <button v-if="!HeadData.User" href="javascript:void(0)" class="nav-link" type="button"
                  data-toggle="modal" data-target="#loginModal" style="width: 100px">
                  登入/註冊
                </button>
                <button v-if="HeadData.User" href="javascript:void(0)" class="nav-link" type="button"
                  @click="gotoMemberCase()" style="width: 100px">
                  會員資訊
                </button>
                <button v-if="HeadData.User" href="javascript:void(0)" class="nav-link" type="button" @click="logout()"
                  style="width: 100px">
                  登出
                </button>
              </div>
              <!-- <li class="nav-item" v-if="!HeadData.User">
                <a
                  href="javascript:void(0)"
                  class="nav-link"
                  type="button"
                  data-toggle="modal"
                  data-target="#loginModal"
                  >登入/註冊</a
                >
              </li>
              <li class="nav-item" v-if="HeadData.User">
                <a
                  href="javascript:void(0)"
                  class="nav-link"
                  type="button"
                  @click="gotoMemberCase()"
                  >會員資訊</a
                >
              </li>
              <li class="nav-item" v-if="HeadData.User">
                <a
                  href="javascript:void(0)"
                  class="nav-link"
                  type="button"
                  v-if="HeadData.User"
                  @click="logout()"
                  >登出</a
                >
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <!-- 撐高nav的高度 -->
    <div class="nav-bg"></div>

    <!-- 會員登入 彈跳視窗 -->
    <div class="modal fade login-box" id="loginModal" tabindex="-1" aria-hidden="true" v-if="!HeadData.User">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="title">
            <h5 class="">會員登入</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="content">
            <div class="form-group">
              <label for="">電子信箱</label>
              <input type="" class="form-control" placeholder="請輸入電子信箱" v-model="account" />
            </div>

            <div class="form-group">
              <label for="">密　碼</label>
              <input type="password" class="form-control" placeholder="請輸入密碼" v-model="password" />
            </div>
            <p>
              <a href="javascript:void(0)" @click="gotoForget()">忘記密碼？</a>
            </p>

            <div class="form-group">
              <label for="">驗證碼</label>
              <div class="code-box">
                <input class="form-control" placeholder="驗證碼" v-model="identifyCode_input" />
                <div class="code">
                  <img :src="identifyCodeData" width="100" height="30" />
                  <!-- <Sidentify :identifyCode="identifyCode"></Sidentify> -->
                  <a href="javascript:void(0)" @click="refreshCode()"><img src="/img/icon-rotate.svg" alt="" /></a>
                  <!-- <a href="javascript:void(0)" @click="playCode()"
                    ><img src="/img/icon-voice.svg" alt=""
                  /></a> -->
                </div>
              </div>
              <!-- <div class="code-box">
                <vue-recaptcha
                  @verify="Verify = true"
                  @expired="Verify = false"
                  ref="recaptchaLogin"
                  sitekey="6Ldnr00iAAAAACDBPIhH7nGYFZW6eLfy060S6_tk"
                ></vue-recaptcha>
              </div> -->
            </div>

            <div class="btn-box">
              <button @click="gotoRegister()">我要註冊</button>
              <button @click="login()">
                會員登入
              </button>
              <!-- <button @click="gotoOldRegister()">舊會員轉換</button> -->
            </div>
            <div class="other-btn" v-if="false">
              <P>其他登入方式：</P>
              <button class="fb"><img src="/img/fb-icon.svg" alt="" /></button>
              <button class="google">
                <img src="/img/google-icon.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 1019 我的最愛 浮動按鈕 -->
    <div class="btn-favorite" type="button" data-toggle="modal" data-target="#favoriteModal"
      v-if="HeadData.User && HeadData.User.Favorite">
      <button>
        <img src="/img/member-favorite.svg" alt="" />
      </button>
    </div>

    <!-- 我的最愛 -->
    <div class="new-case-box favorite" type="button" data-toggle="modal" data-target="#favoriteModal"
      v-if="HeadData.User && HeadData.User.Favorite">
      <div class="modal fade" id="favoriteModal" tabindex="-1" aria-hidden="true" style="padding-left: 0px">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="title">
              <h5 class="">我的最愛</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="">
              <ul class="case-btn">
                <li v-for="(CaseTypeItem, index) in HeadData.CaseTypeItems" :key="index">
                  <a href="javascript:void(0)" @click="newCase(CaseTypeItem.CaseType)">
                    <img :src="HeadData.SiteUrl + CaseTypeItem.CaseType.Image" alt="" />
                    <p>{{ CaseTypeItem.CaseType.Title }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div class="text">
              <p>
                民眾通報案件由權責機關於期限內回復，各類案件回復天數如<span>附表</span>。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { VueRecaptcha } from "vue-recaptcha";
import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";
import Sidentify from "@/components/Identify";

Vue.use(VueAxios, axios);

export default {
  name: "Head",
  components: {
    VueRecaptcha,
    Sidentify,
  },
  data() {
    return {
      HeadData: {},
      account: "",
      password: "",
      Verify: false,
      identifyCode: "",
      identifyCode_input: "",
      identifyCodeData: "",
      //identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      //identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
      //num: 0,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
        {
          params: {
            token: $cookies.get("open_login_token"),
          },
        }
      )
      .then((response) => {
        this.HeadData = response.data;
        if ($cookies.isKey("open_login_token") && !this.HeadData.User) {
          // 帳號在其他地方登入過
          Swal.fire({
            title: "帳號已登出",
            text: "您已在其他地方進行登入",
            icon: "info",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              $cookies.remove("open_login_token");
              if (this.$route.fullPath == "/") {
                location.reload();
              } else {
                this.$router.push({
                  name: "home",
                });
              }
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.refreshCode();
  },
  methods: {
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    gotoRegister: function () {
      $("#loginModal").modal("toggle");
      if (this.$route.fullPath != "/Register") {
        this.$router.push({
          name: "register",
        });
      }
    },
    gotoOldRegister: function () {
      $("#loginModal").modal("toggle");
      if (this.$route.fullPath != "/OldRegister") {
        this.$router.push({
          name: "oldRegister",
        });
      }
    },
    gotoForget: function () {
      $("#loginModal").modal("toggle");
      if (this.$route.fullPath != "/Forget") {
        this.$router.push({
          name: "forget",
        });
      }
    },
    gotoMemberCase: function () {
      $("#loginModal").modal("toggle");
      if (this.$route.fullPath != "/MemberCase") {
        this.$router.push({
          name: "memberCase",
        });
      }
    },
    login: function () {
      if (this.account == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入Email",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.account)) {
        Swal.fire({
          title: "錯誤",
          text: "Email格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.password == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 組合並送出
      let token = AES.encrypt(this.account + "|" + this.password + "|" + this.identifyCode_input + "|" + this.identifyCode);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/Login",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            $cookies.set("open_login_token", res.LoginToken, "30d");
            Swal.fire({
              title: "登入成功",
              text: "即將返回首頁",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 3000,
              willClose: () => {
                $("#loginModal").modal("toggle");
                if (that.$route.fullPath == "/") {
                  location.reload();
                } else {
                  that.$router.push({
                    name: "home",
                  });
                }
              },
            });
            // res.LoginToken
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    logout: function () {
      $cookies.remove("open_login_token");
      if (this.$route.fullPath == "/") {
        location.reload();
      } else {
        this.$router.push({
          name: "home",
        });
      }
    },
    newCase: function (CaseType) {
      if (CaseType.Type == 2) {
        if (CaseType.Blank) {
          window.open(CaseType.Url);
        } else {
          location.href = CaseType.Url;
        }
      } else if (CaseType.Type == 3) {
        Swal.fire({
          width: 800,
          imageUrl: this.HomeData.SiteUrl + CaseType.Image,
          imageWidth: 100,
          imageHeight: 100,
          title: CaseType.Title,
          html: CaseType.PopupContent,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      } else {
        $("#favoriteModal").modal("toggle");
        this.$router.push({
          name: "caseAdd",
          params: {
            CTIId: CaseType.CTIId,
          },
        });
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.identifyCodeData = "";
      this.makeCode();
    },
    makeCode() {
      // for (let i = 0; i < l; i++) {
      //   var code =
      //     this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      //   this.identifyCode += code;
      //   this.identifyCode_sound[i] = code + ".mp3";
      // }
      let that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/GetIdentifyCode",
        type: "post",
        data: {
          //token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.identifyCode = res.identifyCode;
            that.identifyCodeData = res.identifyCodeData;
          }
        },
      });
    },
    // playCode() {
    //   var sound_path = "/voice/numvoice/zh";
    //   var audio = new Audio(
    //     sound_path + "/" + this.identifyCode_sound[this.num]
    //   );
    //   audio.play();
    //   let that = this;
    //   audio.addEventListener(
    //     "ended",
    //     function () {
    //       that.num++;
    //       if (that.num < 4) that.playCode();
    //       if (that.num >= 4) that.num = 0;
    //     },
    //     false
    //   );
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
